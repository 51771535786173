<template>
  <div>
    <div class="open">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="订阅开关">
          <el-switch
            v-model="form.pushType"
            inactive-value="0"
            inactive-text="关"
            active-text="开"
            active-value="1"
            @change="switchBtn(form.pushType)"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="openMain">
      <el-form ref="formList" :model="formList">
        <el-form-item label="推送地址" label-width="100px">
          <el-input v-model="formList.pushUrl"></el-input>
          <div>
            <el-button type="primary" class="submitBtn" @click="testSubmit"
              >测试</el-button
            >
            <el-button type="primary" class="submitBtn" @click="submitBtns"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="推送数据格式" label-width="150px">
          <div style="line-height: 25px">
            {<br /><span v-html="'\u00a0\u00a0\u00a0\u00a0'"></span>
            "data":{<br /><span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span>
            "dateTime":"2023-08-29 14:01:09",//发布时间<br /><span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span>
            "heartRate":0,//心率<br /><span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span>
            "respiratoryRate":0,//呼吸率<br /><span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span>
            "sleepState":0,//睡眠状态<br /><span
              v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"
            ></span>
            }<br /><span v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"></span>
            "dataType":"0", //0:测试数据，1、心跳实时数据<br />
            <span v-html="'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'"></span>
            "imei":"0000000000000000"<br />
            }
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      form: {
        pushType: "1",
      },
      formList: {
        subscribeUrl: "http://testapi.careld.cn/alarm/api/getAlarm",
        params: "GET",
        pushUrl: "",
      },
    };
  },
  mounted() {},
  created() {
    this.getSubscribe();
  },
  destroyed() {},
  methods: {
    //开关
    switchBtn(item) {
      console.log(item, "开关");
      apiShan.updateApiPushStatus({ useStatus: item }).then((res) => {
        // console.log("400中心：", res.data);
        if (res.data.code == 200) {
          console.log(res.data, "查询睡眠数据实时推送配置");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取开查询订阅数据
    getSubscribe() {
      apiShan.getApiPushSleepDataConf().then((res) => {
        // console.log("400中心：", res.data);
        if (res.data.code == 200) {
          console.log(res.data.result, "查询睡眠数据实时推送配置");
          this.form.pushType = res.data.result.useStatus;
          this.formList.pushUrl = res.data.result.pushUrl;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //测试
    testSubmit() {
      apiShan
        .testPushSleepUrl({ pushUrl: this.formList.pushUrl })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            console.log(res.data, "查询测试结果");
            this.$message({
              message: "连接成功",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //保存
    submitBtns() {
      apiShan
        .updateApiPushSleepDataConf({
          pushUrl: this.formList.pushUrl,
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            console.log(res.data, "查询测试结果");
            this.$message({
              message: "保存成功！",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.el-menu-vertical-demo {
  height: 100%;
}
.open {
  width: 96%;
  margin: 0 auto;
}
/deep/.el-form-item__label {
  font-size: 16px;
  text-align: left;
}
.openMain {
  width: 96%;
  margin: 0 auto;
}
/deep/.el-form-item {
  width: 55%;
  margin-bottom: 50px;
}
.submitBtn {
  padding: 9px 40px;
  font-size: 14px;
  margin-top: 4%;
}
.formItem {
  margin-bottom: 20px;
}
</style>